/* eslint-disable no-constant-condition */
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../../../app.config';
import { HelperService } from '../../../../../services/helper-service';
import { ConnectionService } from '../../../../../services/connection-service';
import { TypeIndication, TypeQuestion } from '../indication-type';
import { TypeTree } from '../../../tree-type';
import { QuestionType } from '../../../../../../typings';
import { WindowRefService } from '../../../../../services/window-ref-service';
import { TreeNode } from './tree-modal/tree-node';
import { QuestionOptionMappingModal } from './question-option-mapping';
import { QuestionOptionRearrangeModal } from './question-option-rearrange';
import { AddQuestionModal } from './add-question/add-question.modal';

declare interface NodeMap {
  nodes: Array<{ uniqueIdentifier: string; answer?: string; questionId: string; }>;
  regimen?: string;
  regimens?: Array<string>;
  isConsultation: boolean;
  isEndNode: boolean;
  jumpToTree?: string;
  toRow: any;
}

@Component({
  selector: 'indication-edit',
  templateUrl: './indication-edit.html',
  styleUrls: ['./indication-edit.scss'],
})
export class IndicationEditComponent implements OnDestroy, AfterViewInit {
  AddQuestionType: any = { END_NODE: 'END_NODE', IN_BETWEEN_NODE: 'IN_BETWEEN_NODE', REPLACE_QUESTION: 'REPLACE_QUESTION' };
  @ViewChild('treeBoard', { static: false }) treeBoard: ElementRef;
  tree: TypeTree;
  regimenQuestionMap: Array<TypeIndication>;
  parentTreeName: string;
  indicationTreeObj: any;
  regimens: Array<any>;
  trees: Array<any>;
  questions: any;
  mergeNodeToHideMapping: {} = {};
  treeClasses: Array<string> = [];
  subscriptions: Array<Subscription>;
  mergeNodeArray: Array<any> = [];
  treeTablesOptions: Array<{ display: string; value: string; }> = [];
  treeTopOffset: number = 0;
  insertNode: boolean = false;
  enableReplaceQuestion: boolean = false;
  enableReArrangeOption: boolean = false;
  treeLines: Array<{ fromRow: number, toRow: number, toCol: number, childRow?: number }> = [];
  ui: {
    modal: {
      question: { open: boolean, index: number, excludeIds: Array<string>, type: string, data?: any },
      replaceQuestion: { open: boolean, newQuestion?: any, oldQuestion?: any, oldAnswers?: string[] },
      reArrangeQuestionOption: { open: boolean, currentAnswerSequence?: string[], row?: number, col?: number },
    },
  };

  constructor(public appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private helper: HelperService,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    private dialog: MatDialog) {
    this.treeClasses
      .push(...Object.keys(ApiClientConstant.Regimen.Class).map((key: string) => ApiClientConstant.Regimen.Class[key]));
  }

  ngOnInit(): void {
    this.questions = {};
    this.ui = {
      modal: {
        question: { open: false, index: -1, excludeIds: [], type: this.AddQuestionType.END_NODE },
        replaceQuestion: { open: false },
        reArrangeQuestionOption: { open: false },
      },
    };
    this.subscriptions = [];
    this.trees = [];
    this.regimenQuestionMap = [{ questions: [], isConsultation: false, isTreeJump: false, isEndNode: false }];
    this.indicationTreeObj = new Table.Tree();
    this.tree = {
      class: '',
      name: '',
      questions: [],
      type: this.getTreeType(),
      treeTables: [],
      multiRegimen: undefined,
      active: true,
    };
    this.updateUITreeTablesOptions();
    this.subscribeToUrlQueryParams();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.treeTopOffset = 0;
      let htmlElement = this.treeBoard.nativeElement;
      while (htmlElement) {
        this.treeTopOffset += htmlElement.offsetTop || 0;
        htmlElement = htmlElement.offsetParent;
      }
    }, 0);
  }

  isRegimenSOPTree(): boolean {
    const urlSplit = this.router.url.split('/');
    return urlSplit[3] === 'regimenSops' || urlSplit[3] === 'regimenSop' || urlSplit[3] === 'regimen_sop';
  }

  isActionTree(): boolean {
    const urlSplit = this.router.url.split('/');
    return urlSplit[3] === 'actions' || urlSplit[3] === 'action';
  }

  isAnswerDependentTree(): boolean {
    const urlSplit = this.router.url.split('/');
    return urlSplit[3] === 'answer_dependent';
  }

  isTreatmentDeliveryTree(): boolean {
    const urlSplit = this.router.url.split('/');
    return urlSplit[3] === 'treatment_delivery';
  }

  getTreeType(): string {
    if (this.isRegimenSOPTree()) return ApiClientConstant.Tree.Type.REGIMEN_SOP;
    if (this.isActionTree()) return ApiClientConstant.Tree.Type.ACTION;
    if (this.isAnswerDependentTree()) return ApiClientConstant.Tree.Type.ANSWER_DEPENDENT;
    if (this.isTreatmentDeliveryTree()) return ApiClientConstant.Tree.Type.TREATMENT_DELIVERY;
    return ApiClientConstant.Tree.Type.INDICATION;
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.parent.params.subscribe((params: any) => {
      this.parentTreeName = params.name;
      if (this.router.url.endsWith('new')) this.fetchTrees();
    }));
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.subTree) {
        this.indicationTreeObj = this.route.parent.snapshot.data.subTree;
        this.helper.convertParseToDictionary(this.indicationTreeObj, this.tree, { treeTables: [] });
        this.questions = {};
        this.tree.questions.forEach((question: any) => (this.questions[question.get('uniqueIdentifier')] = question));
        this.regimenQuestionMap = this.indicationTreeObj.get('nodeMap')
          .map(({ regimen, nodes, isConsultation, jumpToTree, isEndNode, toRow, regimens }: any) => {
            const questions = nodes.map(({ uniqueIdentifier, answer }: any) => {
              const title = this.questions[uniqueIdentifier].get('title');
              if (!answer && this.questions[uniqueIdentifier].get('inputs').length) {
                return { answer: '_default', title, uniqueIdentifier };
              }
              return { answer, title, uniqueIdentifier };
            });
            return { regimen, regimens, questions, isConsultation, jumpToTree, isTreeJump: !!jumpToTree, isEndNode: !!isEndNode, toRow };
          });
        this.updateUITreeTablesOptions();
        this.generateTreeLinesFromRegimenQuestionMap();
        this.fetchTrees();
        this.generateMergeNodeArray();
      }
      this.fetchRegimens();
    }));
  }

  async fetchTrees(): Promise<void> {
    if (this.tree.type === ApiClientConstant.Tree.Type.TREATMENT_DELIVERY) {
      this.conn.getTreeList({ where: { version: this.parentTreeName, type: this.tree.type }, notName: this.tree.name }, 1000)
        .then((trees: Array<any>) => (this.trees = trees));
    } else {
      this.conn.getTreeList({ where: { version: this.parentTreeName }, notName: this.tree.name }, 1000)
        .then((trees: Array<any>) => (this.trees = trees));
    }
  }

  autoCompleteOnSelect(event: any): void {
    const { value }: any = event.target;
    if (!value || this.tree.treeTables.includes(value)) {
      return;
    }
    this.tree.treeTables.push(value);
    this.updateUITreeTablesOptions();
  }

  removeItem(index: number): void {
    this.tree.treeTables.splice(index, 1);
    this.updateUITreeTablesOptions();
  }

  async fetchRegimens(): Promise<void> {
    this.regimens = await this.conn.findRegimens({
      where: { type: ApiClientConstant.Regimen.Type.MAIN, active: true, class: this.tree.class },
      project: ['regimenId'],
      ascending: 'regimenId',
    });
  }

  searchQuestion(index: number, type: string, skipExcludeIds?: boolean): void {
    this.ui.modal.question.type = type;
    if (!skipExcludeIds) {
      this.ui.modal.question.excludeIds = this.regimenQuestionMap[index].questions
        .filter((question: TypeQuestion) => (
          this.questions[question.uniqueIdentifier].get('type') === ApiClientConstant.Question.Type.SEND_TO_USER))
        .map((question: TypeQuestion) => question.uniqueIdentifier);
    } else {
      this.ui.modal.question.excludeIds = [];
    }
    this.ui.modal.question.index = index;
    this.openAddQuestionModal();
  }

  openAddQuestionModal(): void {
    const dialogRef = this.dialog.open(AddQuestionModal, {
      panelClass: 'w-full',
      data: { excludeIds: this.ui.modal.question.excludeIds },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((question: any) => {
      if (question) {
        this.addQuestion(question);
      }
    }));
  }

  // noinspection JSUnusedGlobalSymbols
  dragStart(event: any, index: number): void {
    event.dataTransfer.setData('data', JSON.stringify({ fromRow: index }));
  }

  // noinspection JSUnusedGlobalSymbols
  dragDrop(event: any, toRow: number, toCol: number): void {
    const { fromRow }: { fromRow: number } = JSON.parse(event.dataTransfer.getData('data'));
    if (fromRow === toRow) {
      alert('Link can not be created for same branch.');
      return;
    }
    this.addLine(fromRow, toRow, toCol);
  }

  addLine(fromRow: number, toRow: number, toCol: number): void {
    this.regimenQuestionMap[fromRow].toRow = { row: toRow, col: toCol };
    this.treeLines.push({ fromRow, toCol, toRow });
  }

  removeDropJump(regimenQuestion_: TypeIndication, fromRow: number): void {
    const regimenQuestion = regimenQuestion_;
    const { toRow }: any = regimenQuestion;
    delete regimenQuestion.toRow;
    this.treeLines = this.treeLines.filter((line: any) => !(line.fromRow === fromRow && toRow.row === line.toRow));
  }

  // noinspection JSUnusedGlobalSymbols
  allowDrop(event: any): void {
    event.preventDefault();
  }

  // noinspection JSUnusedGlobalSymbols
  addQuestion(question: any): void {
    if (!question) return;
    if (this.ui.modal.question.type === this.AddQuestionType.REPLACE_QUESTION) {
      this.mapQuestionResponse(question);
      return;
    }
    if (this.ui.modal.question.type === this.AddQuestionType.END_NODE) {
      this.addQuestionInEnd(this.ui.modal.question.index, question);
      this.generateMergeNodeArray();
      this.forceUpdateRegimenQuestionMap();
      return;
    }
    this.addQuestionBeforeNode(this.ui.modal.question.data.row, this.ui.modal.question.data.col, question);
    this.generateMergeNodeArray();
    this.forceUpdateRegimenQuestionMap();
  }

  addQuestionInEnd(row: number, question: any, answerOrder: string[] = []): void {
    this.questions[question.get('uniqueIdentifier')] = question;
    const regimenQuestion = this.regimenQuestionMap.splice(row, 1)[0];
    // let defaultAnswerValue;
    if (question.get('inputs').length) {
      if (question.get('inputs').length > 1) {
        delete regimenQuestion.regimen;
      }
      // if (this.tree.type === ApiClientConstant.Tree.Type.TREATMENT_DELIVERY) {
      //   const { option, index: userDefaultAnswerSelectionValue }: any = this.displayPopUpForDefaultAnswer(question);
      //   if (option === 'CANCEL') {
      //     this.regimenQuestionMap.splice(row, 0, regimenQuestion);
      //     return;
      //   }
      //   defaultAnswerValue = question.get('inputs')[userDefaultAnswerSelectionValue];
      // }
      const currentInputs = question.get('inputs')
        .filter((input: QuestionType.Button) => (!answerOrder.length || answerOrder.includes(input.value || '_default')));
      const newRegimenQuestions = currentInputs
        .sort((item1: QuestionType.Button, item2: QuestionType.Button) => {
          if (!answerOrder.length) return 0;
          const valueOfItem1 = item1.value || item1.text || '_default';
          const valueOfItem2 = item2.value || item2.text || '_default';
          const indexOfItem1 = answerOrder.indexOf(valueOfItem1);
          const indexOfItem2 = answerOrder.indexOf(valueOfItem2);
          if (indexOfItem1 === indexOfItem2) return 0;
          if (indexOfItem1 >= indexOfItem2) return -1;
          return 1;
        })
        .map((input: QuestionType.Button) => {
          const newRegimenQuestion: TypeIndication = JSON.parse(JSON.stringify(regimenQuestion));
          delete newRegimenQuestion.jumpToTree;
          newRegimenQuestion.isTreeJump = false;
          newRegimenQuestion.isConsultation = false;
          newRegimenQuestion.isEndNode = false;
          const newNode: { answer:string, title:string, uniqueIdentifier:string } = {
            answer: input.value || input.text,
            title: question.get('title'),
            uniqueIdentifier: question.get('uniqueIdentifier'),
          };
          newRegimenQuestion.questions.push(newNode);
          return newRegimenQuestion;
        });
      newRegimenQuestions.forEach((item: any) => this.regimenQuestionMap.splice(row, 0, item));
      this.treeLines.forEach((line_: { fromRow: number, toRow: number, toCol: number }) => {
        const line = line_;
        if (line.fromRow > row) {
          line.fromRow += (currentInputs.length - 1);
        }
        if (line.toRow > row) {
          line.toRow += (currentInputs.length - 1);
          this.regimenQuestionMap[line.fromRow].toRow.row = line.toRow;
        }
      });
    } else {
      regimenQuestion.questions.push({
        title: question.get('title'),
        uniqueIdentifier: question.get('uniqueIdentifier'),
      });
      this.regimenQuestionMap.splice(row, 0, regimenQuestion);
    }
  }

  /**
   * Returns all row numbers of siblings (matching parent)
   * Tree:
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2-> q0.a1 -> q1.a1
   * 2. regimen3-> q0.a1 -> q1.a2
   * 3. regimen4-> q0.a2 -> q2.a0
   *
   * Eg:1
   * Input: 0,1  (q1.a0)
   * Output: [0]
   *
   * Eg:2
   * Input: 1,1  (q1.a1)
   * Output: [1,2]
   */
  findSiblingRows(row: number, col: number): Array<number> {
    const minQuestionMatch = this.regimenQuestionMap[row].questions.filter((x: any, index: number) => (index <= col));
    return this.findIndexMatchingQuestionOrder(minQuestionMatch);
  }

  findIndexMatchingQuestionOrder(questionList: Array<TypeQuestion>): Array<number> {
    const result: Array<number> = [];
    this.regimenQuestionMap.forEach((regimenQuestion: TypeIndication, index: number) => {
      const isPerfectMatch = questionList.every((question: TypeQuestion, qIndex: number) => {
        if (!regimenQuestion.questions[qIndex]) return false;
        let isMatch = question.uniqueIdentifier === regimenQuestion.questions[qIndex].uniqueIdentifier;
        if (qIndex < questionList.length - 1) {
          isMatch = isMatch && (question.answer === regimenQuestion.questions[qIndex].answer);
        }
        return isMatch;
      });
      if (isPerfectMatch) result.push(index);
    });
    return result;
  }

  /**
   * Returns all row numbers of siblings (matching parent)
   * Tree:
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2-> q0.a1 -> q1.a1
   * 2. regimen3-> q0.a1 -> q1.a2
   * 3. regimen4-> q0.a2 -> q2.a0
   *
   * Eg:1
   * Input: 0,1  (q1.a0)
   * Output: [0]
   *
   * Eg:2
   * Input: 1,0  (q0.a1)
   * Output: [1,2]
   */
  getRowsOfChildNodes(row: number, col: number): Array<number> {
    const minQuestionMatchForAnswer = this.regimenQuestionMap[row].questions.filter((x: any, index: number) => (index <= col));
    return this.findIndexMatchingQuestionOrderWithCurrentNodeAnswer(minQuestionMatchForAnswer);
  }

  findIndexMatchingQuestionOrderWithCurrentNodeAnswer(questionList: Array<TypeQuestion>): Array<number> {
    const result: Array<number> = [];
    this.regimenQuestionMap.forEach((regimenQuestion: TypeIndication, index: number) => {
      const isPerfectMatch = questionList.every((question: TypeQuestion, qIndex: number) => {
        if (!regimenQuestion.questions[qIndex]) return false;
        let isMatch = question.uniqueIdentifier === regimenQuestion.questions[qIndex].uniqueIdentifier;
        if (qIndex < questionList.length) {
          isMatch = isMatch && (question.answer === regimenQuestion.questions[qIndex].answer);
        }
        return isMatch;
      });
      if (isPerfectMatch) result.push(index);
    });
    return result;
  }

  private removeLinesToOrFromPoint(siblingRows: Array<number>, toRow: number, forCol: number): number {
    const linesToRemove = this.treeLines.filter((line_: any) => {
      const line = line_;
      if (!siblingRows.includes(line.toRow) && !siblingRows.includes(toRow)) return false;
      if (siblingRows.includes(line.fromRow)) return true;
      if (siblingRows.includes(line.toRow)) {
        if (line.toCol >= forCol) return true;
        line.toRow = siblingRows[0];
        this.regimenQuestionMap[line.fromRow].toRow.row = siblingRows[0];
      }
      return false;
    });
    linesToRemove.forEach((line: any) => {
      const regimenQuestion = this.regimenQuestionMap[line.fromRow];
      this.removeDropJump(regimenQuestion, line.fromRow);
    });
    return linesToRemove.length;
  }

  doesNodeHaveAnySubtree(siblingRows: Array<number>, question: any, col: number): boolean {
    if (siblingRows.length !== question.get('inputs').length) return false;
    return siblingRows.every((row: number) => !this.regimenQuestionMap[row].questions[col + 1]);
  }

  displayPopUpToGetSubTreeSelectionValue(question: any): { option: string, index: number } {
    let options = question.get('inputs').map((input: QuestionType.Button) => input.value || input.text);
    options.push('Delete all');
    options = options.map((value: string, index: number) => `${index + 1}. ${value}`);
    const number = prompt(`Which option tree do you want to keep?\n${options.join('\n')}`);
    if (!number) return { option: 'CANCEL', index: undefined };
    if (isNaN(Number(number)) || Number(number) <= 0 || Number(number) > options.length) {
      alert('Please select valid option number.');
      return this.displayPopUpToGetSubTreeSelectionValue(question);
    }
    if (Number(number) === options.length) {
      return { option: 'DELETE_ALL', index: undefined };
    }
    return { option: 'DELETE_ONE', index: Number(number) - 1 };
  }

  /**
   * Returns all row numbers of siblings (matching parent)
   * Tree:
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2-> q0.a1 -> q1.a0 -> q2.a0
   * 2. regimen3-> q0.a1 -> q1.a1 -> q3.a0
   * 3. regimen4-> q0.a2 -> q2.a0
   *
   * Eg:1
   * Input: 1, 0 or 2, 0
   * Output:
   * | q1.a0 q2.a0 |
   * | q1.a1 q1.a1 |
   *
   * Eg:2
   * Input: 3, 0
   * Output:
   * | q2.0 |
   *
   * @param row Row id of the node
   * @param col Col id of the node
   * @return Subtrees in Matrix format
   */
  getSubTreeOfNode(row: number, col: number): Array<any> {
    const rows = this.getRowsOfChildNodes(row, col);
    return new Array(rows[rows.length - 1] - rows[0] + 1)
      .fill(0)
      .map((zero: number, index: number) => this.regimenQuestionMap[rows[index]].questions
        .filter((item: any, questionIndex: number) => (questionIndex > col)));
  }

  /**
   * Returns all row numbers of siblings (matching parent)
   * Tree:
   * 0.               q0.a0 -> q1.a0 -> q2.a0  **>> jump to q2.a0
   * 1. regimen2:     q0.a1 -> q1.a0 -> q2.a0
   * 2. consultation: q0.a1 -> q1.a1 -> q3.a0
   * 3. regimen4:     q0.a2 -> q2.a0 -> Tree jump to v4_tree2
   *
   * Eg:1
   * Input: [0,1,2,3]
   * Output:
   * [
   * {
   *     regimen: undefined,
   *     toRow: 1,
   *     isConsultation: undefined,
   *     isEndNode: undefined,
   *     isTreeJump: undefined,
   *     jumpToTree: undefined,
   * },
   * {
   *     regimen: regimen2,
   *     toRow: undefined,
   *     isConsultation: undefined,
   *     isEndNode: undefined,
   *     isTreeJump: undefined,
   *     jumpToTree: undefined,
   * },
   * {
   *     regimen: undefined,
   *     toRow: undefined,
   *     isConsultation: true,
   *     isEndNode: undefined,
   *     isTreeJump: undefined,
   *     jumpToTree: undefined,
   * },
   * {
   *     regimen: undefined,
   *     toRow: undefined,
   *     isConsultation: undefined,
   *     isEndNode: undefined,
   *     isTreeJump: true,
   *     jumpToTree: v4_tree2,
   * }
   * ]
   *
   * @param rows
   */
  getLeafInformationForRows(rows: Array<number>): Array<object> {
    return rows.map((questionIndex: number) => {
      const regimenQuestionMapping = this.regimenQuestionMap[questionIndex];
      return {
        regimens: regimenQuestionMapping.regimens,
        regimen: regimenQuestionMapping.regimen,
        toRow: regimenQuestionMapping.toRow,
        isConsultation: regimenQuestionMapping.isConsultation,
        isEndNode: regimenQuestionMapping.isEndNode,
        isTreeJump: regimenQuestionMapping.isTreeJump,
        jumpToTree: regimenQuestionMapping.jumpToTree,
      };
    });
  }

  applyLeafInformationAfterRemovalOfNode(rowsLeafInformation: Array<any>, startRow: number, deletedRows: Array<number>): void {
    rowsLeafInformation.forEach((leafInformation_: any, index: number) => {
      const leafInformation = leafInformation_;
      if (leafInformation.toRow) {
        const reductionInRow = deletedRows.filter((rowToDelete: number) => (rowToDelete < leafInformation.toRow.row)).length;
        leafInformation.toRow.col -= 1;
        leafInformation.toRow.row -= reductionInRow;
      }
      Object.assign(this.regimenQuestionMap[startRow + index], leafInformation);
    });
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Asks the user to select which subtree to preserve and then deletes the node
   *
   * Tree:
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2: q0.a1 -> q1.a0 -> q4.a0
   * 2. regimen3: q0.a1 -> q1.a1 -> q3.a0
   * 3. regimen4: q0.a2 -> q2.a0
   *
   * Input: 1,1
   * Output
   * There will be a popup asking for 1) keep a0 subtree 2) keep a1 subtree 3) delete all
   * If option 1 is selected new tree will be
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2: q0.a1 -> q4.a0
   * 2. regimen4: q0.a2 -> q2.a0
   *
   * If option 2 was selected
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen3: q0.a1 -> q3.a0
   * 2. regimen4: q0.a2 -> q2.a0
   *
   * If option 3 was selected
   * 0. regimen1: q0.a0 -> q1.a0 -> q2.a0
   * 1. regimen2: q0.a1
   * 2. regimen4: q0.a2 -> q2.a0
   *
   * @param row row id of the node to be deleted
   * @param col column id of the node to be deleted
   */
  removeQuestionWhileKeepingOnePartOfTree(row: number, col: number): void {
    const minQuestionMatch = this.regimenQuestionMap[row].questions.filter((x: any, index: number) => (index <= col));
    const siblingRows = this.findSiblingRows(row, col);
    const question = this.questions[minQuestionMatch[minQuestionMatch.length - 1].uniqueIdentifier];

    // either there is no subtree to process or user has selected delete all.
    if (this.doesNodeHaveAnySubtree(siblingRows, question, col)) {
      this.removeQuestionAndItsSubtrees(row, col, true);
      this.generateMergeNodeArray();
      this.forceUpdateRegimenQuestionMap();
      return;
    }

    let keepOptionSubtreeIndex;
    // If the node has only one subtree then keep subtree.
    // Eg. Inline questions or GetStarted question which has only one button.
    if (question.get('inputs').length === 1 || question.get('inputs').length === 0) {
      keepOptionSubtreeIndex = 0;
    } else {
      // Ask user to select which subtree to keep if user has not selected any yet.
      const { option, index: userSubTreeSelectionValue }: any = this
        .displayPopUpToGetSubTreeSelectionValue(question);
      if (option === 'CANCEL') return;
      if (option === 'DELETE_ALL') {
        this.removeQuestionAndItsSubtrees(row, col, true);
        this.generateMergeNodeArray();
        this.forceUpdateRegimenQuestionMap();
        return;
      }
      keepOptionSubtreeIndex = userSubTreeSelectionValue;
    }

    const [subtreeToBePreservedDuringNodeDeletion, answerRow]: any = this
      .getSubtreeToBePreservedDuringNodeDeletionWithAnswerRow(question, keepOptionSubtreeIndex, siblingRows, col);
    const rowsOfChildNodes = this.getRowsOfChildNodes(answerRow, col);
    const rowsToDelete = this.getRowsToBeDeleted(siblingRows, rowsOfChildNodes);
    if (this.regimenQuestionMap[rowsOfChildNodes[0]].questions.length === (col + 1)) {
      this.removeDropJump(this.regimenQuestionMap[rowsOfChildNodes[0]], rowsOfChildNodes[0]);
    }
    const leafInformationOfRowsToBeDeleted = this.getLeafInformationForRows(rowsOfChildNodes);

    this.removeQuestionAndItsSubtrees(row, col, true);
    this.addChildQuestions(siblingRows[0], subtreeToBePreservedDuringNodeDeletion);

    this.applyLeafInformationAfterRemovalOfNode(leafInformationOfRowsToBeDeleted, siblingRows[0], rowsToDelete);
    this.generateTreeLinesFromRegimenQuestionMap();
    this.generateMergeNodeArray();
    this.forceUpdateRegimenQuestionMap();
  }

  private getRowsToBeDeleted(siblingRows: Array<any>, rowsOfChildNodes: Array<any>): Array<any> {
    return siblingRows.filter((questionRow: number) => !rowsOfChildNodes.includes(questionRow));
  }

  private getSubtreeToBePreservedDuringNodeDeletionWithAnswerRow(question: any, keepOptionSubtreeIndex: number,
    siblingRows: Array<number>, col: number): [Array<any>, number] {
    if (!question.get('inputs').length) {
      const answerRow = siblingRows[0];
      return [this.getSubTreeOfNode(answerRow, col), answerRow];
    }
    const answer = question.get('inputs')[keepOptionSubtreeIndex].value
      || question.get('inputs')[keepOptionSubtreeIndex].text;
    const answerRow = siblingRows.find((each: number) => (
      this.regimenQuestionMap[each].questions[col].answer === answer));
    return [this.getSubTreeOfNode(answerRow, col), answerRow];
  }

  private generateTreeLinesFromRegimenQuestionMap(): void {
    this.treeLines = [];
    this.regimenQuestionMap.forEach((regimenQuestionMap: TypeIndication, index: number) => {
      if (!regimenQuestionMap.toRow) return;
      const { toRow }: any = regimenQuestionMap;
      const { childArray }: any = this
        .generateSiblingRowsChildArrayAndLeafInformation(toRow.row, toRow.col);
      this.treeLines.push({ fromRow: index, toRow: toRow.row, toCol: toRow.col, childRow: childArray.length });
    });
    this.treeLines.sort((line1: any, line2: any) => {
      if (line1.fromRow === line2.fromRow) return 0;
      if (line1.fromRow > line2.fromRow) return 1;
      return -1;
    });
  }

  /**
   * Remove current node and all children
   * @param row
   * @param col
   * @param disableForceLineUpdateInUI . Disable it when there is an action immediately after this action.
   */
  removeQuestionAndItsSubtrees(row: number, col: number, disableForceLineUpdateInUI: boolean = false): void {
    const regimenQuestion = this.regimenQuestionMap[row];
    delete regimenQuestion.regimen;
    const minQuestionMatch = regimenQuestion.questions.filter((x: any, index: number) => (index <= col));
    const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
    this.regimenQuestionMap.forEach((item: any) => {
      if (!item.toRow) return;
      this.removeLinesToOrFromPoint(siblingRows, item.toRow.row, col);
    });
    this.regimenQuestionMap.forEach((item_: any, currentRow: number) => {
      const item = item_;
      if (!item.toRow) return;
      const line = this.treeLines.find(({ toRow, fromRow }: any) => (
        item.toRow.row === toRow && currentRow === fromRow));
      if (!line) return;
      if (line.fromRow > siblingRows[siblingRows.length - 1]) {
        line.fromRow -= (siblingRows.length - 1);
      }
      if (item.toRow.row > siblingRows[siblingRows.length - 1]) {
        line.toRow -= (siblingRows.length - 1);
        item.toRow.row -= (siblingRows.length - 1);
      }
    });
    if (!disableForceLineUpdateInUI) this.forceUpdateLine();
    siblingRows.reverse().forEach((index: number) => this.regimenQuestionMap.splice(index, 1));
    minQuestionMatch.pop();
    regimenQuestion.questions = minQuestionMatch;
    delete regimenQuestion.toRow;
    delete regimenQuestion.jumpToTree;
    delete regimenQuestion.isTreeJump;
    delete regimenQuestion.isEndNode;
    delete regimenQuestion.isConsultation;
    delete regimenQuestion.regimen;
    delete regimenQuestion.regimens;
    this.regimenQuestionMap.splice(siblingRows[siblingRows.length - 1], 0, regimenQuestion);
  }

  findUniqueQuestions(): any {
    const uniqueQuestions = {};
    this.regimenQuestionMap.forEach((regimenQuestion: TypeIndication) => {
      regimenQuestion.questions.forEach((question: TypeQuestion) => {
        if (!uniqueQuestions[question.uniqueIdentifier]) {
          uniqueQuestions[question.uniqueIdentifier] = this.questions[question.uniqueIdentifier];
        }
      });
    });
    return uniqueQuestions;
  }

  createNodeMap(): any {
    const nodeMapList = [];
    this.regimenQuestionMap.forEach((regimenQuestion: TypeIndication) => {
      const nodeMap: NodeMap = {
        nodes: regimenQuestion.questions
          .map(({ uniqueIdentifier, answer }: TypeQuestion) => {
            const question = this.questions[uniqueIdentifier];
            return { uniqueIdentifier, answer, questionId: question.id };
          }),
        isConsultation: regimenQuestion.isConsultation,
        isEndNode: regimenQuestion.isEndNode,
        toRow: regimenQuestion.toRow,
      };
      if (regimenQuestion.toRow) {
        delete nodeMap.jumpToTree;
        delete nodeMap.regimen;
        delete nodeMap.regimens;
        nodeMap.isConsultation = false;
        nodeMap.isEndNode = false;
      } else if (regimenQuestion.isTreeJump) {
        nodeMap.jumpToTree = regimenQuestion.jumpToTree;
        delete nodeMap.toRow;
        delete nodeMap.regimen;
        delete nodeMap.regimens;
        nodeMap.isConsultation = false;
        nodeMap.isEndNode = false;
      } else if (regimenQuestion.isEndNode) {
        delete nodeMap.toRow;
        delete nodeMap.regimen;
        delete nodeMap.regimens;
        nodeMap.isConsultation = false;
        delete nodeMap.jumpToTree;
      } else if (regimenQuestion.isConsultation) {
        nodeMap.regimen = regimenQuestion.regimen;
        delete nodeMap.toRow;
        delete nodeMap.regimen;
        delete nodeMap.regimens;
        delete nodeMap.jumpToTree;
        nodeMap.isEndNode = false;
      } else if (this.tree.multiRegimen) {
        delete nodeMap.regimen;
        nodeMap.regimens = regimenQuestion.regimens;
      } else {
        delete nodeMap.regimens;
        nodeMap.regimen = regimenQuestion.regimen;
      }
      nodeMapList.push(nodeMap);
    });
    return nodeMapList;
  }

  // noinspection JSUnusedGlobalSymbols
  saveTree(): void {
    if (!this.tree.name.startsWith(`${this.parentTreeName}_`)) {
      this.tree.name = `${this.parentTreeName}_${this.tree.name}`;
    }
    const uniqueQuestions = this.findUniqueQuestions();
    this.tree.questions = Object.keys(uniqueQuestions).map((key: string) => uniqueQuestions[key]);
    const nodeMap = this.createNodeMap();
    this.helper.convertDictionaryToParse(this.tree, this.indicationTreeObj);
    this.indicationTreeObj.set('nodeMap', nodeMap);
    const isNew = !!this.indicationTreeObj.id;
    this.indicationTreeObj.save()
      .then((treesHavingCycle: Array<string>) => {
        if (treesHavingCycle && treesHavingCycle.length) {
          throw new Error(`you have created a cycle in following trees: \
          ${treesHavingCycle.reduce((prev: string, cur: string) => `${prev} ${cur}`, '')} \n please undo your last change`);
        }
      })
      .then(() => {
        if (this.isAnswerDependentTree()) {
          return this.router.navigate(
            [`${isNew ? '../' : ''}../../answer_dependent`, this.indicationTreeObj.get('name')],
            { relativeTo: this.route });
        }
        if (this.isRegimenSOPTree()) {
          return this.router.navigate(
            [`${isNew ? '../' : ''}../../regimen_sop`, this.indicationTreeObj.get('name')],
            { relativeTo: this.route });
        }
        return this.router.navigate(
          [`${isNew ? '../' : ''}../../${this.isActionTree() ? 'action' : 'indication'}`, this.indicationTreeObj.get('name')],
          { relativeTo: this.route });
      })
      .catch((err: any) => {
        this.tree.name = this.tree.name.split('_')[1];
        alert(err.message.message || err.message);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.appConfig;
    delete this.router;
    delete this.route;
    delete this.helper;
    delete this.conn;
    delete this.subscriptions;
    delete this.tree;
    delete this.indicationTreeObj;
  }

  addNewPathToTree(questions: Array<TypeQuestion>, rootTreeNode_: TreeNode, row: number): TreeNode {
    if (!questions.length) return rootTreeNode_;
    const rootTreeNode = rootTreeNode_ || new TreeNode(questions[0].uniqueIdentifier);
    let previousAnswer: string;
    let previousTreeNode: TreeNode;
    let currentTreeNode = rootTreeNode;
    questions.forEach((question: TypeQuestion, col: number) => {
      if (previousTreeNode) {
        currentTreeNode = previousTreeNode.addChild(previousAnswer, question.uniqueIdentifier);
      }
      currentTreeNode.addRowCol(row, col);
      previousAnswer = question.answer;
      previousTreeNode = currentTreeNode;
    });
    return rootTreeNode;
  }

  findTreeNode(rootTreeNode: TreeNode, questions_: Array<TypeQuestion>): TreeNode {
    const questions = questions_.map((each: TypeQuestion) => each);
    const question = questions.shift();
    if (!question) return rootTreeNode;
    const childNode = rootTreeNode.findChildNode(question.answer);
    if (!childNode) return rootTreeNode;
    return this.findTreeNode(childNode, questions);
  }

  generateGraph(regimenQuestionMap: Array<TypeIndication>): TreeNode {
    let rootTreeNode: TreeNode;
    const reverseRegimenQuestionMap = regimenQuestionMap.map((each: any) => each).reverse();
    reverseRegimenQuestionMap.forEach((row: TypeIndication, index: number) => {
      rootTreeNode = this.addNewPathToTree(row.questions, rootTreeNode, regimenQuestionMap.length - index - 1);
    });
    reverseRegimenQuestionMap.forEach((row: TypeIndication) => {
      const leafQuestion = row.questions[row.questions.length - 1];
      const leafTreeNode = this.findTreeNode(rootTreeNode, row.questions);
      if (row.isEndNode) {
        leafTreeNode.addChild(leafQuestion.answer, '##endNode');
        return;
      }
      if (row.isConsultation) {
        leafTreeNode.addChild(leafQuestion.answer, '##consultation');
        return;
      }
      if (row.regimen) {
        leafTreeNode.addChild(leafQuestion.answer, `##regimen_${row.regimen}`);
        return;
      }
      if (row.regimens) {
        leafTreeNode.addChild(leafQuestion.answer, `##regimens_${row.regimens.sort().join('|')}`);
        return;
      }
      if (row.isTreeJump) {
        leafTreeNode.addChild(leafQuestion.answer, `##jumpToTree_${row.jumpToTree}`);
        return;
      }
      if (row.toRow) {
        const pointToTreeNode = this.findTreeNode(rootTreeNode, this.regimenQuestionMap[row.toRow.row].questions.slice(0, row.toRow.col));
        leafTreeNode.addChildTreeNode(leafQuestion.answer, pointToTreeNode);
      }
    });
    return rootTreeNode;
  }

  findPointersToMatchHash(
    treeNode: TreeNode,
    hashMap_: { [key: string]: [TreeNode, number] },
    pointers: Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }>,
    depth: number = 0,
    col: number = 0): [TreeNode, number] {
    const { inOrderTraversal }: { inOrderTraversal: string } = treeNode;
    const hashMap = hashMap_;
    if (hashMap[inOrderTraversal]) {
      return hashMap[inOrderTraversal];
    }
    hashMap[inOrderTraversal] = [treeNode, col];
    treeNode.childNodes.forEach((childNode: TreeNode, colIndex: number) => {
      const [toTreeNode, nodeToPoint]: [TreeNode, number] = this
        .findPointersToMatchHash(childNode, hashMap, pointers, depth + 1, colIndex);
      if (nodeToPoint === -1 || !toTreeNode.childAt(nodeToPoint)) return;
      pointers.push({
        from: treeNode,
        fromChild: treeNode.childAt(colIndex),
        to: toTreeNode,
        toChild: toTreeNode.childAt(nodeToPoint),
      });
    });
    return [treeNode, -1];
  }

  generatePointers(rootNode: TreeNode): Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }> {
    const hashMap = {};
    const pointers: Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }> = [];
    this.findPointersToMatchHash(rootNode, hashMap, pointers);
    return pointers;
  }

  generateLinesFromPointers(pointers: Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }>):
    Array<{ fromRow: number, fromCol: number, toRow: number, toCol: number, childRow: number }> {
    return pointers.map((pointer: { from: TreeNode, to: TreeNode, toChild: string, fromChild: string }) => {
      const fromPoint: { row: number, col: number } = pointer.from.rowCol
        .find(({ row, col }: { row: number, col: number }) => (
          `${this.regimenQuestionMap[row].questions[col].answer}` === pointer.fromChild));
      const toPoint: { row: number, col: number } = pointer.to.rowCol
        .find(({ row, col }: { row: number, col: number }) => (
          `${this.regimenQuestionMap[row].questions[col].answer}` === pointer.toChild));
      const fromSiblings = this.getRowsOfChildNodes(fromPoint.row, fromPoint.col);
      const toSiblings = this.findSiblingRows(toPoint.row, toPoint.col);
      const fromRow = Math.min(...fromSiblings);
      const toRow = Math.min(...toSiblings);
      return { fromRow, fromCol: fromPoint.col, toRow, toCol: toPoint.col, childRow: toSiblings.length };
    });
  }

  generateMissingLines(lines: Array<{ fromRow: number; fromCol: number, toRow: number; toCol: number; childRow: number }>):
    Array<{ fromRow: number; fromCol: number, toRow: number; toCol: number; childRow: number }> {
    return lines.filter((line1: { fromRow: number; fromCol: number, toRow: number; toCol: number; childRow: number }) => !this.treeLines
      .some((line2: { fromRow: number; toRow: number; toCol: number; childRow: number }) => ['fromRow', 'toRow', 'toCol', 'childRow']
        .every((field: string) => line1[field] === line2[field])));
  }

  addNewLines(newLines: Array<{ fromRow: number; fromCol: number, toRow: number; toCol: number; childRow: number }>): void {
    newLines.sort((line1: any, line2: any) => {
      if (line1.fromRow === line2.fromRow) return 0;
      if (line1.fromRow > line2.fromRow) return 1;
      return -1;
    });
    let reductionInRows = 0;
    newLines.forEach((line: { fromRow: number; fromCol: number, toRow: number; toCol: number; childRow: number }, index: number) => {
      const fromRow = line.fromRow - reductionInRows;
      if (this.regimenQuestionMap[fromRow].questions[line.fromCol + 1]) {
        const originalRowLength = this.regimenQuestionMap.length;
        this.removeQuestionAndItsSubtrees(fromRow, line.fromCol + 1, true);
        const newRowLength = this.regimenQuestionMap.length;
        reductionInRows += (originalRowLength - newRowLength);
      }
      const toRow = line.toRow - reductionInRows;
      this.addLine(fromRow, toRow, line.toCol);
    });
  }

  filterPointersToEndNode(pointers: Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }>):
    Array<{ from: TreeNode, to: TreeNode, toChild: string, fromChild: string }> {
    return pointers
      .filter(({ to }: { to: TreeNode }) => !['endNode', 'jumpToTree', 'consultation', 'regimen']
        .some((key: string) => to.nodeName.startsWith(`##${key}`)));
  }

  startTreeCompress(): void {
    const rootNode = this.generateGraph(this.regimenQuestionMap);
    const pointers = this.generatePointers(rootNode);
    const pointersNotToEndNode = this.filterPointersToEndNode(pointers);
    const lines = this.generateLinesFromPointers(pointersNotToEndNode);
    const newLines = this.generateMissingLines(lines);
    this.addNewLines(newLines);
    this.generateTreeLinesFromRegimenQuestionMap();
    this.generateMergeNodeArray();
    this.forceUpdateRegimenQuestionMap();
  }

  forceUpdateRegimenQuestionMap(): void {
    const { regimenQuestionMap }: any = this;
    const lines = this.treeLines;
    this.treeLines = [];
    this.regimenQuestionMap = [];
    setTimeout(() => {
      this.regimenQuestionMap.push(...regimenQuestionMap);
      this.treeLines.push(...lines);
    }, 0);
  }

  forceUpdateLine(): void {
    const lines = this.treeLines;
    this.treeLines = [];
    setTimeout(() => (this.treeLines.push(...lines)), 0);
  }

  generateMergeNodeArray(): void {
    this.mergeNodeArray = [];
    this.processSiblingsToGenerateNodesThatCanBeMerged(0, this.regimenQuestionMap.length - 1, 0, this.mergeNodeArray);
    this.updateMergeDisplayNodes();
  }

  private updateMergeDisplayNodes(): void {
    this.mergeNodeToHideMapping = this.generateMergeNodeToHideMapping(this.mergeNodeArray);
    const filteredMergeNodeArray = this.mergeNodeArray.filter((item: any) => {
      const batches = [];
      const points = [].concat(item.pointsToSplit).concat([item.x2]);
      let startRow = item.x1;
      points.forEach((endRow: number) => {
        batches.push({ startRow, endRow: endRow - 1 });
        startRow = endRow;
      });
      batches[batches.length - 1].endRow += 1;
      const visibleBatches = batches
        .filter((batch: any) => new Array(batch.endRow - batch.startRow + 1)
          .fill(0)
          .some((zero: number, index: number) => !this.mergeNodeToHideMapping[batch.startRow + index].hide));
      return visibleBatches.length === 1;
    });
    if (filteredMergeNodeArray.length !== this.mergeNodeArray.length) {
      this.mergeNodeArray = filteredMergeNodeArray;
      this.updateMergeDisplayNodes();
      return;
    }
    this.treeLines.forEach((line_: any) => {
      const line = line_;
      if (this.mergeNodeToHideMapping[line.fromRow] && this.mergeNodeToHideMapping[line.fromRow].hide) return;
      if (!this.mergeNodeToHideMapping[line.toRow] || !this.mergeNodeToHideMapping[line.toRow].hide) return;
      const minQuestionMatch = this.regimenQuestionMap[line.toRow].questions.filter((x: any, index: number) => (index <= line.toCol));
      const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
      const firstVisibleRow = siblingRows
        .find((row: any) => (!this.mergeNodeToHideMapping[row] || !this.mergeNodeToHideMapping[row].hide));
      this.regimenQuestionMap[line.fromRow].toRow.row = firstVisibleRow;
      line.toRow = firstVisibleRow;
    });
  }

  /**
   * Merge NodeArray
   *  [
   *  {"x1":4,"x2":7,"col":2,"pointsToSplit":[5,6]},
   *  {"x1":1,"x2":2,"col":2,"pointsToSplit":[2]},
   *  {"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}
   * ]
   */
  private processSiblingsToGenerateNodesThatCanBeMerged(x1: number, x2: number, col: number, mergeNodeArray: Array<any>): Array<string> {
    if (x1 === x2) {
      let nodeName: string;
      const regimenQuestionMap = this.regimenQuestionMap[x1];
      if (regimenQuestionMap.questions[col]) {
        const result = this.processSiblingsToGenerateNodesThatCanBeMerged(x1, x2, col + 1, mergeNodeArray);
        const question = this.regimenQuestionMap[x1].questions[col];
        return result.map((path: string) => `${question.uniqueIdentifier}${
          question.answer ? `|${question.answer}` : ''}^${path}`);
      }
      if (regimenQuestionMap.regimen) {
        nodeName = regimenQuestionMap.regimen;
      } else if (regimenQuestionMap.regimens) {
        nodeName = regimenQuestionMap.regimens.sort().join('|');
      } else if (regimenQuestionMap.isTreeJump) {
        nodeName = regimenQuestionMap.jumpToTree;
      } else if (regimenQuestionMap.isEndNode) {
        nodeName = 'endNode';
      } else if (regimenQuestionMap.isConsultation) {
        nodeName = 'consultation';
      } else if (regimenQuestionMap.toRow) {
        const minQuestionMatch = this.regimenQuestionMap[regimenQuestionMap.toRow.row]
          .questions.filter((x: any, index: number) => (index <= regimenQuestionMap.toRow.col));
        const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
        return this.processSiblingsToGenerateNodesThatCanBeMerged(
          siblingRows[0],
          siblingRows[siblingRows.length - 1],
          regimenQuestionMap.toRow.col,
          mergeNodeArray);
      } else {
        nodeName = `${this.windowRef.nativeWindow.performance.now()}`;
      }
      return [nodeName];
    }
    let currentStartPoint = x1;
    const siblingsGroups: Array<Array<string>> = [];
    const pointsToSplit = [];
    while (currentStartPoint <= x2) {
      pointsToSplit.push(currentStartPoint);
      const minQuestionMatch = this.regimenQuestionMap[currentStartPoint].questions.filter((x: any, index: number) => (index <= col));
      const siblingRows = this.findIndexMatchingQuestionOrderWithCurrentNodeAnswer(minQuestionMatch);
      const newX1 = siblingRows[0];
      const newX2 = siblingRows[siblingRows.length - 1];
      const newCol = col + 1;
      currentStartPoint = newX2 + 1;
      const result = this.processSiblingsToGenerateNodesThatCanBeMerged(newX1, newX2, newCol, mergeNodeArray);
      siblingsGroups.push(result);
    }
    const firstSibling = siblingsGroups.shift();
    const isExactMatch = siblingsGroups.every((sibling: Array<string>) => {
      if (firstSibling.length !== sibling.length) return false;
      return sibling.every((item: string, index: number) => (item === firstSibling[index]));
    });
    pointsToSplit.shift();
    if (siblingsGroups.length && isExactMatch) {
      if (mergeNodeArray.every((item: any) => !(item.x1 === x1 && item.x2 === x2 && item.col === col))) {
        mergeNodeArray.push({ x1, x2, col, pointsToSplit });
      }
    }
    const result = [];
    currentStartPoint = x1;
    siblingsGroups.unshift(firstSibling);
    siblingsGroups.forEach((siblingGroup: Array<any>, index: number) => {
      const question = this.regimenQuestionMap[currentStartPoint].questions[col];
      result.push(...siblingGroup.map((nodeName: string) => `${question.uniqueIdentifier}${
        question.answer ? `|${question.answer}` : ''}^${nodeName}`));
      currentStartPoint = pointsToSplit[index];
    });
    return result;
  }

  /**
   *  {
   *    "0":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "hide":true
   *     },
   *     "1":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"NO [OR] YES","item":{"x1":1,"x2":2,"col":2,"pointsToSplit":[2]}},
   *      "hide":true
   *     },
   *     "2":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"NO [OR] YES","item":{"x1":1,"x2":2,"col":2,"pointsToSplit":[2]}},
   *      "hide":false
   *     },
   *     "3":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "hide":false
   *     },
   *     "4":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"Regularly [OR] Rarely [OR] No","item":{"x1":4,"x2":7,"col":2,"pointsToSplit":[5,6]}},
   *      "hide":true
   *     },
   *     "5":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"Regularly [OR] Rarely [OR] No","item":{"x1":4,"x2":7,"col":2,"pointsToSplit":[5,6]}},
   *      "hide":true
   *     },
   *     "6":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"Regularly [OR] Rarely [OR] No","item":{"x1":4,"x2":7,"col":2,"pointsToSplit":[5,6]}},
   *      "hide":false
   *     },
   *     "7":{
   *      "0":{"answer":"Female [OR] Male","item":{"x1":0,"x2":7,"col":0,"pointsToSplit":[1]}},
   *      "2":{"answer":"Regularly [OR] Rarely [OR] No","item":{"x1":4,"x2":7,"col":2,"pointsToSplit":[5,6]}},
   *      "hide":false
   *     }
   *    }
   */
  private generateMergeNodeToHideMapping(mergeNodeArray: any[]): object {
    const mergeNodeToHideMapping = {};
    mergeNodeArray.forEach((item: any) => {
      const answers = [];
      [item.x1].concat(item.pointsToSplit)
        .forEach((row: any) => answers
          .push(this.regimenQuestionMap[row].questions[item.col].answer));
      new Array(item.x2 - item.x1 + 1)
        .fill(0)
        .forEach((value: number, index: number) => {
          if (!mergeNodeToHideMapping[item.x1 + index]) {
            mergeNodeToHideMapping[item.x1 + index] = { hide: true };
          }
          mergeNodeToHideMapping[item.x1 + index][item.col] = { answer: answers.join(' [OR] '), item };
        });
      return true;
    });
    const displayMatrix = this.generateDisplayMatrix(mergeNodeArray);
    displayMatrix.forEach((showRow: boolean, row: number) => {
      if (!mergeNodeToHideMapping[row]) return;
      mergeNodeToHideMapping[row].hide = !showRow;
    });
    return mergeNodeToHideMapping;
  }

  /**
   * [false,false,true,true,false,false,true,true]
   */
  private generateDisplayMatrix(mergeNodeArray: any[]): Array<boolean> {
    const displayMatrix = new Array(this.regimenQuestionMap.length)
      .fill(0).map(() => []);
    const maxCol = Object.keys(this.questions).length;
    const ROW_VALUE = {
      UNSET: 0,
      HAS_VALUE: 1,
      CAN_BE_MERGED: 2,
      HIDE: 3,
      SHOW: 4,
    };
    new Array(maxCol).fill(0)
      .map((value: number, index: number) => index).reverse()
      .forEach((col: number) => {
        const colMergeArray = mergeNodeArray.filter((item: any) => (item.col === col));
        displayMatrix.forEach((rowArray: Array<any>, row: number) => {
          displayMatrix[row][col] = displayMatrix[row][col + 1];
          if (displayMatrix[row][col]) return;
          const question = this.regimenQuestionMap[row].questions[col];
          displayMatrix[row][col] = question ? ROW_VALUE.HAS_VALUE : ROW_VALUE.UNSET;
        });
        colMergeArray.forEach((item: any) => {
          const itemArray = new Array(item.x2 - item.x1 + 1)
            .fill(0)
            .map((x: any, index: number) => (item.x1 + index));
          itemArray.forEach((itemRow: number) => {
            if (displayMatrix[itemRow][col] >= ROW_VALUE.HIDE) return;
            displayMatrix[itemRow][col] = ROW_VALUE.CAN_BE_MERGED;
          });
          const hasAtLeastOneRowToShow = itemArray
            .some((itemRow: number) => (displayMatrix[itemRow][col] === 4));
          if (hasAtLeastOneRowToShow) {
            itemArray.forEach((itemRow: number) => {
              if (displayMatrix[itemRow][col] !== ROW_VALUE.CAN_BE_MERGED) return;
              displayMatrix[itemRow][col] = ROW_VALUE.HIDE;
            });
            return;
          }
          const rowToShow = itemArray.pop();
          itemArray.forEach((itemRow: number) => (displayMatrix[itemRow][col] = ROW_VALUE.HIDE));
          displayMatrix[rowToShow][col] = ROW_VALUE.SHOW;
        });
        displayMatrix.forEach((rowArray_: Array<any>) => {
          const rowArray = rowArray_;
          if (rowArray[col] !== ROW_VALUE.HAS_VALUE) return;
          rowArray[col] = ROW_VALUE.SHOW;
        });
      });
    return displayMatrix.map((rowArray: Array<number>) => (rowArray[0] === 4));
  }

  // noinspection JSUnusedGlobalSymbols
  expandNode(row: number, col: number): void {
    const startRow = this.mergeNodeToHideMapping[row][col].item.x1;
    const endRow = this.mergeNodeToHideMapping[row][col].item.x2;
    this.mergeNodeArray = this.mergeNodeArray.filter((item: any) => !(
      this.mergeNodeToHideMapping[row][col].item.x1 === item.x1
      && this.mergeNodeToHideMapping[row][col].item.x2 === item.x2
      && this.mergeNodeToHideMapping[row][col].item.col === item.col));
    this.updateMergeDisplayNodes();
    this.treeLines.forEach((line_: any) => {
      const line = line_;
      if (startRow <= line.fromRow && line.fromRow <= endRow) return;
      if (startRow <= line.toRow && line.toRow <= endRow) {
        this.updateLineToPointFirstVisibleRow(line);
        return;
      }
      if (startRow >= line.toRow || line.toRow >= endRow) return;
      this.updateLineToPointFirstVisibleRow(line);
    });
  }

  updateLineToPointFirstVisibleRow(line_: any): void {
    const line = line_;
    const minQuestionMatch = this.regimenQuestionMap[line.toRow].questions.filter((x: any, index: number) => (index <= line.toCol));
    const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
    const firstVisibleRow = siblingRows
      .find((siblingRow: any) => (!this.mergeNodeToHideMapping[siblingRow] || !this.mergeNodeToHideMapping[siblingRow].hide));
    this.regimenQuestionMap[line.fromRow].toRow.row = firstVisibleRow;
    line.toRow = firstVisibleRow;
  }

  addQuestionBeforeNode(row: number, col: number, question: any): void {
    if (!question) {
      this.ui.modal.question.data = { row, col };
      this.searchQuestion(row, this.AddQuestionType.IN_BETWEEN_NODE);
      return;
    }
    const { siblingRows, childArray, rowsLeafInformation }: any = this
      .generateSiblingRowsChildArrayAndLeafInformation(row, col);

    this.removeQuestionAndItsSubtrees(row, col, true);

    this.addQuestionInEnd(siblingRows[0], question);
    if (question.get('inputs').length) {
      question.get('inputs').map((x: any) => x).reverse().forEach((input: any, index: number) => {
        this.addChildQuestions(siblingRows[0] + index * childArray.length, childArray);
      });
      const totalRowsAdded = question.get('inputs').length * childArray.length;
      question.get('inputs').map((x: any) => x).reverse().forEach((input: any, blockNumber: number) => {
        const additionInRows = blockNumber * childArray.length;
        this.updateLeafInformationToRowsFrom(rowsLeafInformation, 1, additionInRows, siblingRows, totalRowsAdded);
      });
    } else {
      this.addChildQuestions(siblingRows[0], childArray);
      this.updateLeafInformationToRowsFrom(rowsLeafInformation, 1, 0, siblingRows, childArray.length);
    }
    this.generateTreeLinesFromRegimenQuestionMap();
  }

  private updateLeafInformationToRowsFrom(
    rowsLeafInformation: any[][],
    additionInCol: number,
    additionInRows: number,
    siblingRows: Array<number>,
    totalRowsAdded: number): void {
    rowsLeafInformation.forEach((leafInformation_: any, index: number) => {
      const leafInformation = JSON.parse(JSON.stringify(leafInformation_));
      if (leafInformation.toRow) {
        if (leafInformation.isSubTreeJump) {
          leafInformation.toRow.row += (siblingRows[0] + additionInRows);
        } else if (leafInformation.toRow.row > siblingRows[0]) {
          if (leafInformation.toRow.row <= siblingRows[siblingRows.length - 1]) {
            leafInformation.toRow.row += additionInRows;
          } else {
            leafInformation.toRow.row += (totalRowsAdded - siblingRows.length);
          }
        }
        if (siblingRows[0] <= leafInformation.toRow.row && leafInformation.toRow.row <= (siblingRows[0] + totalRowsAdded - 1)) {
          leafInformation.toRow.col += additionInCol;
        }
      }
      delete leafInformation.isSubTreeJump;
      Object.assign(this.regimenQuestionMap[siblingRows[0] + additionInRows + index], leafInformation);
    });
  }

  private generateSiblingRowsChildArrayAndLeafInformation(row: number, col: number)
    : { siblingRows: number[], childArray: TypeQuestion[][], rowsLeafInformation: any[] } {
    const minQuestionMatch = this.regimenQuestionMap[row].questions.filter((x: any, index: number) => (index <= col));
    const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
    const childArray = new Array(siblingRows[siblingRows.length - 1] - siblingRows[0] + 1)
      .fill(0)
      .map((zero: number, index: number) => this.regimenQuestionMap[siblingRows[index]].questions
        .filter((item: any, questionIndex: number) => (questionIndex >= col)));
    const rowsLeafInformation = siblingRows.map((questionIndex: number) => {
      const regimenQuestionMapping = this.regimenQuestionMap[questionIndex];
      let isSubTreeJump = false;
      const toRow = regimenQuestionMapping.toRow
        ? JSON.parse(JSON.stringify(regimenQuestionMapping.toRow))
        : regimenQuestionMapping.toRow;
      if (toRow && siblingRows.includes(toRow.row)) {
        isSubTreeJump = true;
        toRow.row -= siblingRows[0];
      }
      return {
        regimen: regimenQuestionMapping.regimen,
        regimens: regimenQuestionMapping.regimens,
        toRow,
        isSubTreeJump,
        isConsultation: regimenQuestionMapping.isConsultation,
        isEndNode: regimenQuestionMapping.isEndNode,
        isTreeJump: regimenQuestionMapping.isTreeJump,
        jumpToTree: regimenQuestionMapping.jumpToTree,
      };
    });
    return { siblingRows, childArray, rowsLeafInformation };
  }

  // noinspection JSMethodCanBeStatic
  private checkIfInputAnswerIsSame(input: QuestionType.Button, answer: string): boolean {
    const inputAnswer = input.value || input.text || '_default';
    if (!answer && inputAnswer === '_default') return true;
    return answer === inputAnswer;
  }

  private addChildQuestions(row: number, childsArray: TypeQuestion[][]): void {
    if (!childsArray.length) return;
    const firstChild = childsArray[0];
    if (!firstChild.length) return;
    const nextQuestionToAdd = this.questions[firstChild[0].uniqueIdentifier];
    const answers = [];
    childsArray.forEach((childArray: TypeQuestion[]) => {
      const question = childArray[0];
      if (!question.answer || answers.includes(question.answer)) return;
      answers.push(question.answer);
    });
    this.addQuestionInEnd(row, nextQuestionToAdd, answers);
    let currentRow = row;
    if (nextQuestionToAdd.get('inputs').length) {
      nextQuestionToAdd
        .get('inputs')
        .map((x: any) => x)
        .reverse()
        .sort((item1: any, item2: any) => {
          const indexOfItem1 = answers.indexOf((item1.value || item1.text));
          const indexOfItem2 = answers.indexOf((item2.value || item2.text));
          if (indexOfItem1 === indexOfItem2) return 0;
          if (indexOfItem1 > indexOfItem2) return 1;
          return -1;
        })
        .forEach((input: QuestionType.Button) => {
          const nextChildArray = childsArray
            .filter((childArray: Array<TypeQuestion>) => this.checkIfInputAnswerIsSame(input, childArray[0].answer))
            .map((childArray: Array<TypeQuestion>) => childArray.slice(1, childArray.length));
          this.addChildQuestions(currentRow, nextChildArray);
          currentRow += nextChildArray.length;
        });
      return;
    }
    const nextChildArray = childsArray
      .filter((childArray: Array<TypeQuestion>) => (
        childArray[0].uniqueIdentifier === this.questions[firstChild[0].uniqueIdentifier].get('uniqueIdentifier')))
      .map((childArray: Array<TypeQuestion>) => childArray.slice(1, childArray.length));
    this.addChildQuestions(row, nextChildArray);
  }

  validateQuestions(): void {
    this.startTreeDecompress();
    this.treeLines = [];
    let isTreeValid = false;
    let maxTry = 200;
    while (!isTreeValid && maxTry) {
      isTreeValid = this.validateQuestionsOptions(0, this.regimenQuestionMap.length - 1, 0);
      if (!maxTry) {
        alert('Max Try to validate question have exceed.');
      }
      maxTry -= 1;
    }
    if (maxTry === 199) {
      this.startTreeCompress();
    }
  }

  validateQuestionsOptions(r1: number, r2: number, col: number): boolean {
    if (r1 === r2) {
      if (this.regimenQuestionMap[r1].questions[col]) {
        const questionInfo = this.regimenQuestionMap[r1].questions[col];
        const isTreeValid = this.validateQuestionsOptions(r1, r2, col + 1);
        if (!isTreeValid) return isTreeValid;
        return this.processQuestionToCheckIfOptionHaveBeenModified(r1, col, [questionInfo.answer || '_default'], [r1]);
      }
      return true;
    }
    let currentStartPoint = r1;
    const answers = [];
    const pointsToSplit = [];
    while (currentStartPoint <= r2) {
      pointsToSplit.push(currentStartPoint);
      answers.push(this.regimenQuestionMap[currentStartPoint].questions[col].answer || '_default');
      const minQuestionMatch = this.regimenQuestionMap[currentStartPoint].questions.filter((x: any, index: number) => (index <= col));
      const siblingRows = this.findIndexMatchingQuestionOrderWithCurrentNodeAnswer(minQuestionMatch);
      const newR1 = siblingRows[0];
      const newR2 = siblingRows[siblingRows.length - 1];
      const newCol = col + 1;
      currentStartPoint = newR2 + 1;
      const isTreeValid = this.validateQuestionsOptions(newR1, newR2, newCol);
      if (!isTreeValid) return isTreeValid;
    }
    return this.processQuestionToCheckIfOptionHaveBeenModified(r1, col, answers, pointsToSplit);
  }

  private processQuestionToCheckIfOptionHaveBeenModified(row: number, col: number,
    answers: string[], pointsToSplit: number[]): boolean {
    const question = this.questions[this.regimenQuestionMap[row].questions[col].uniqueIdentifier];
    const questionNewAnswers = question.get('inputs').length
      ? question.get('inputs').map((input: any) => (input.value || input.text || '_default'))
      : ['_default'];
    const answersToAdd = questionNewAnswers.filter((questionAnswer: string) => !answers.includes(questionAnswer));
    if (answersToAdd.length) {
      this.addQuestionAnswerToTree(row, col, answersToAdd);
      return false;
    }
    const answersToRemove = answers.filter((questionAnswer: string) => !questionNewAnswers.includes(questionAnswer));
    if (answersToRemove.length) {
      this.removeQuestionAnswersFromTree(answersToRemove, pointsToSplit, answers, col);
      return false;
    }
    return true;
  }

  private addQuestionAnswerToTree(r1: number, col: number, answersToAdd: string[]): void {
    const minQuestionMatch = this.regimenQuestionMap[r1].questions.filter((x: any, index: number) => (index <= col));
    const siblingRows = this.findIndexMatchingQuestionOrder(minQuestionMatch);
    const lastSiblingRow = siblingRows[siblingRows.length - 1];
    answersToAdd.forEach((answer: string) => {
      const questions: Array<TypeQuestion> = JSON.parse(JSON.stringify(minQuestionMatch));
      questions[questions.length - 1].answer = answer;
      this.regimenQuestionMap.splice(
        lastSiblingRow + 1,
        0,
        {
          isEndNode: false,
          isTreeJump: false,
          isConsultation: false,
          questions,
        });
    });
  }

  private removeQuestionAnswersFromTree(answersToRemove: string[], pointsToSplit: number[], answers: string[], col: number): void {
    const rowsToRemove: number[][] = [];
    answersToRemove.forEach((answer: string) => {
      const answerRow = pointsToSplit[answers.indexOf(answer)];
      const minQuestionMatch = this.regimenQuestionMap[answerRow].questions.filter((x: any, index: number) => (index <= col));
      const siblingRows = this.findIndexMatchingQuestionOrderWithCurrentNodeAnswer(minQuestionMatch);
      rowsToRemove.push(siblingRows);
    });
    rowsToRemove.sort((item1: number[], item2: number[]) => {
      if (item1[0] === item2[0]) return 0;
      if (item1[0] > item2[0]) return -1;
      return 1;
    });
    rowsToRemove.forEach((item: number[]) => {
      item.reverse().forEach((rowToDelete: number) => this.regimenQuestionMap.splice(rowToDelete, 1));
    });
  }

  startTreeDecompress(maxDepth: number = 40): void {
    if (!maxDepth) {
      alert('Max tree decompress depth reached');
      throw Error('Max tree decompress depth reached');
    }
    this.generateTreeLinesFromRegimenQuestionMap();
    const line: any = this.treeLines.find((checkForLine: any) => {
      const checkForLineSiblingRows = this.findSiblingRows(checkForLine.toRow, checkForLine.toCol);
      const numberOfLinesStartFromSiblingRows = checkForLineSiblingRows.filter((row: number) => this.regimenQuestionMap[row].toRow).length;
      return !numberOfLinesStartFromSiblingRows;
    });
    if (!line) return;
    const { childArray, rowsLeafInformation }: any = this
      .generateSiblingRowsChildArrayAndLeafInformation(line.toRow, line.toCol);
    const row = line.fromRow;
    this.removeDropJump(this.regimenQuestionMap[row], row);
    this.addChildQuestions(row, childArray);
    this.updateLeafInformationToRowsFrom(rowsLeafInformation, 0, 0, [row], childArray.length);
    this.startTreeDecompress(maxDepth - 1);
  }

  // noinspection JSUnusedGlobalSymbols
  replaceQuestion(row: number, col: number): void {
    this.ui.modal.question.data = { row, col };
    this.searchQuestion(row, this.AddQuestionType.REPLACE_QUESTION, true);
  }

  private mapQuestionResponse(newQuestion: any): void {
    this.questions[newQuestion.get('uniqueIdentifier')] = newQuestion;
    const oldQuestion = this.questions[this.regimenQuestionMap[this.ui.modal.question.data.row]
      .questions[this.ui.modal.question.data.col].uniqueIdentifier];
    const siblings = this.findSiblingRows(this.ui.modal.question.data.row, this.ui.modal.question.data.col);
    const answers = [];
    siblings.forEach((siblingRow: number) => {
      const { answer }: { answer?: string } = this.regimenQuestionMap[siblingRow].questions[this.ui.modal.question.data.col];
      if (!answer || answers.includes(answer)) return;
      answers.push(answer);
    });
    this.ui.modal.replaceQuestion.oldAnswers = answers;
    this.ui.modal.replaceQuestion.oldQuestion = oldQuestion;
    this.ui.modal.replaceQuestion.newQuestion = newQuestion;
    if (!newQuestion.get('inputs').length && !oldQuestion.get('inputs').length) {
      this.replaceQuestionWithMapping({ _default: '_default' });
      return;
    }
    this.openQuestionOptionMappingModal();
  }

  openQuestionOptionMappingModal(): void {
    const dialogRef = this.dialog.open(QuestionOptionMappingModal, {
      panelClass: 'w-full',
      data: {
        oldQuestion: this.ui.modal.replaceQuestion.oldQuestion,
        newQuestion: this.ui.modal.replaceQuestion.newQuestion,
        oldQuestionOptions: this.ui.modal.replaceQuestion.oldAnswers,
      },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((oldToNewQuestionMapping: any) => {
      this.replaceQuestionWithMapping(oldToNewQuestionMapping);
    }));
  }

  replaceQuestionWithMapping(oldToNewQuestionMapping: any): void {
    if (!oldToNewQuestionMapping) return;
    const { row, col }: any = this.ui.modal.question.data;
    const { newQuestion }: any = this.ui.modal.replaceQuestion;
    const newUniqueIdentifier = this.ui.modal.replaceQuestion.newQuestion.get('uniqueIdentifier');
    const siblingRows = this.findSiblingRows(row, col);
    siblingRows.forEach((siblingRow: number) => {
      const question = this.regimenQuestionMap[siblingRow].questions[col];
      question.uniqueIdentifier = newUniqueIdentifier;
      const defaultKey: string = '_default';
      let newAnswer: string;
      if (!question.answer) {
        if (oldToNewQuestionMapping[defaultKey]) {
          newAnswer = oldToNewQuestionMapping[defaultKey];
        }
      } else if (oldToNewQuestionMapping[question.answer]) {
        newAnswer = oldToNewQuestionMapping[question.answer];
      }
      if (!newQuestion.get('inputs').length) {
        if (!newAnswer) {
          question.answer = `${new Date().getTime()}`;
          return;
        }
        newAnswer = undefined;
      }
      question.answer = newAnswer;
    });
    this.validateQuestions();
  }

  // noinspection JSUnusedGlobalSymbols
  reArrangeOptions(row: number, col: number): void {
    const siblingRows = this.findSiblingRows(row, col);
    const answers = [];
    siblingRows.forEach((siblingRow: number) => {
      const { answer }: { answer?: string } = this.regimenQuestionMap[siblingRow].questions[col];
      if (!answer || answers.includes(answer)) return;
      answers.push(answer);
    });
    this.ui.modal.reArrangeQuestionOption.row = row;
    this.ui.modal.reArrangeQuestionOption.col = col;
    this.ui.modal.reArrangeQuestionOption.currentAnswerSequence = answers;
    this.openQuestionOptionRearrangeModal();
  }

  openQuestionOptionRearrangeModal(): void {
    const dialogRef = this.dialog.open(QuestionOptionRearrangeModal, {
      panelClass: 'w-full',
      data: { currentAnswerSequence: this.ui.modal.reArrangeQuestionOption.currentAnswerSequence },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((newAnswerSequence: string[]) => {
      this.performReArrangeQuestion(newAnswerSequence);
    }));
  }

  // noinspection JSUnusedGlobalSymbols
  performReArrangeQuestion(newAnswerSequence: string[]): void {
    if (!newAnswerSequence) return;
    const { row, col }: { row?: number, col?: number } = this.ui.modal.reArrangeQuestionOption;
    this.reArrangeQuestionForAnswerSequence(newAnswerSequence, row, col);
  }

  updateMultiRegimens(regimenQuestion_: any, regimens: Array<any>): void {
    const regimenQuestion = regimenQuestion_;
    regimenQuestion.regimens = regimens.map((each: any) => each.get('regimenId'));
  }

  private reArrangeQuestionForAnswerSequence(answerSequence: string[], row: number, col: number): void {
    const answer = answerSequence.shift();
    if (!answer) return;
    const answers = [];
    const answersIndex: { [key: string]: number } = {};
    const siblingRows = this.findSiblingRows(row, col);
    siblingRows.forEach((siblingRow: number) => {
      const currentAnswer = this.regimenQuestionMap[siblingRow].questions[col].answer;
      if (!currentAnswer || Object.keys(answersIndex).includes(currentAnswer)) return;
      answers.push(currentAnswer);
      answersIndex[currentAnswer] = siblingRow;
    });
    if (answer !== answers[answers.length - answerSequence.length - 1]) {
      const rowsToMove = this.getRowsOfChildNodes(answersIndex[answer], col);
      const indexToMove = answersIndex[answers[answers.length - answerSequence.length - 1]];
      const firstRowToMove = rowsToMove[0];
      const endRowToMove = rowsToMove[rowsToMove.length - 1];
      this.treeLines.forEach((line: any) => {
        if (indexToMove <= line.toRow && line.toRow <= endRowToMove) {
          if (rowsToMove.includes(line.toRow)) {
            this.regimenQuestionMap[line.fromRow].toRow.row = indexToMove + (line.toRow - firstRowToMove);
          } else {
            this.regimenQuestionMap[line.fromRow].toRow.row += rowsToMove.length;
          }
        }
      });
      const items = this.regimenQuestionMap.splice(firstRowToMove, rowsToMove.length);
      this.regimenQuestionMap.splice(indexToMove, 0, ...items);
      this.generateTreeLinesFromRegimenQuestionMap();
    }
    this.reArrangeQuestionForAnswerSequence(answerSequence, row, col);
  }

  private updateUITreeTablesOptions(): void {
    this.treeTablesOptions = Object.keys(ApiClientConstant.TreeTables).map((key: string) => ({
      display: key.replace(/_/g, ' '),
      value: ApiClientConstant.TreeTables[key],
    })).filter((each: { value: string }) => !this.tree.treeTables.includes(each.value));
  }

  toggleTreeStatus(): void {
    this.tree.active = !this.tree.active;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
